import React from "react";
import UI from "../../imgs/UI.png";
import BG from "../../imgs/header-bg.svg";
import BG2 from "../../imgs/bg_2.svg";
import BG3 from "../../imgs/bg_3.svg";
import GetInTouch from "../../imgs/get_in_touch.svg";
import TrendCircle from "../../imgs/trend-circle.svg";
import CheckedCircle from "../../imgs/checked-circle.svg";
import ReportCircle from "../../imgs/report-circle.svg";
import FileCircle from "../../imgs/file-circle.svg";
import BoxCircle from "../../imgs/box-circle.svg";
import LoyalCircle from "../../imgs/loyal-circle.svg";
import LocationCircle from "../../imgs/location-circle.svg";
import UserCircle from "../../imgs/user-circle.svg";
import AnalyticCircle from "../../imgs/analytic-circle.svg";
import TransportCircle from "../../imgs/transport-circle.svg";

import ChartCircle from "../../imgs/chart-circle.svg";

import dollar_icon from "../../imgs/icons/dollar.svg";
import box_icon from "../../imgs/icons/box.svg";
import calendar_icon from "../../imgs/icons/calendar.svg";
import location_icon from "../../imgs/icons/location.svg";
import team_icon from "../../imgs/icons/team.svg";
import checkmark_icon from "../../imgs/icons/checkmark.svg";
import { useModal } from "react-simple-modal-provider";

import {
  WaiButtonOrange,
  WaiButtonOrangeOutLine,
} from "../../styles/styled_button";

function Home() {
  const { open: openModal } = useModal("BookDemo");
  return (
    <div>
      <section className="header-section">
        <div className="container-page">
          <div className="wrap-left-panel">
            <img src={TrendCircle} alt="" />
            <h1>Take Your Dispensary to The Next Level</h1>
            <p>
              WAIO is your all-encompassing retail management platform built to
              optimize your dispensary’s daily operations and provide you
              additional revenue opportunities.
            </p>
            <WaiButtonOrange onClick={openModal}>Learn More</WaiButtonOrange>
          </div>
          <img className="right-bg" src={BG} alt="" />
        </div>
      </section>
      <section className="section-detail">
        <div className="container-page">
          <div className="flex">
            <img src={BG2} alt="" />
            <div className="wrap-right-pagel">
              <img src={CheckedCircle} alt="" />
              <h2>
                The All-in-One Management Platform – Purpose Built for Cannabis
                Dispensaries.
              </h2>
              <p>
                WAIO is an all-encompassing retail management platform built to
                optimize your daily operations and empower you with the tools to
                outperform the competition. WAIO’s complete set of features is
                guaranteed to save your team valuable time and improve your
                overall sales efforts.
              </p>
              <WaiButtonOrangeOutLine onClick={openModal}> Request a Demo </WaiButtonOrangeOutLine>
            </div>
          </div>
        </div>
      </section>
      <section className="section-detail grey">
        <div className="container-page">
          <div className="">
            <h2 className="text-center">The Only Platform You Need</h2>
            <div className="wrapper-grid">
              <div className="white-panel">
                <img src={ReportCircle} alt="" />
                <h3>Compliance & Reporting</h3>
                <p>
                  Let our compliance and reporting modules help your team save
                  countless hours, reduce errors and streamline the overall
                  reporting process.
                </p>
              </div>
              <div className="white-panel">
                <img src={FileCircle} alt="" />
                <h3>In-store & Online Menus</h3>
                <p>
                  Take control of your in store and online menus with our
                  comprehensive set of menu tools. Our menus are built with your
                  customer in mind and sync directly with your Point-of-Sale and
                  product stock.
                </p>
              </div>
              <div className="white-panel">
                <img src={BoxCircle} alt="" />
                <h3>Inventory Management</h3>
                <p>
                  Optimize your back-of-house efforts with our management tools
                  that simplify inventory organization, data entry and product
                  details all of which tying into our reporting tools.
                </p>
              </div>
              <div className="white-panel">
                <img src={AnalyticCircle} alt="" />
                <h3>Performance Analytics</h3>
                <p>
                  Data at your fingertips to help you analyze how your products
                  and dispensaries are performing. Our wide range of data points
                  lets you identify what works and what doesn’t helping maximize
                  your dispensary performance.
                </p>
              </div>
              <div className="white-panel">
                <img src={TransportCircle} alt="" />
                <h3>Manage Order Tracking</h3>
                <p>
                  Manage and track all your orders (in-store, self-serve and
                  online) in one consolidated portal. Delivery, in-store,
                  self-serve kiosks and curbside pickup – the ordering platform
                  handles it all.
                </p>
              </div>
              <div className="white-panel">
                <img src={LoyalCircle} alt="" />
                <h3>Personalized Loyalty Program</h3>
                <p>
                  Incentivize your customers by launching your own personalized
                  loyalty program. Customers can earn product specific
                  discounts, points and subscribe to your mailing lists.
                </p>
              </div>
              <div className="white-panel">
                <img src={UserCircle} alt="" />
                <h3>Dispensary-Producer Ad Market</h3>
                <p>
                  Take advantage of our producer portal by working directly with
                  licensed producers to crate both online and in-store
                  advertising opportunities. Harness your dispensary’s reach and
                  create additional revenue streams.
                </p>
              </div>
              <div className="white-panel">
                <img src={LocationCircle} alt="" />
                <h3>Multi-Store Management</h3>
                <p>
                  Built for both the single and multi-location WAIO will
                  streamline your operations and manage all your stores with one
                  consolidated platform.
                </p>
              </div>
            </div>

            <WaiButtonOrangeOutLine onClick={openModal} className="d-block-center">
              Find out More
            </WaiButtonOrangeOutLine>
          </div>
        </div>
      </section>

      <section className="section-detail">
        <div className="container-page">
          <div className="flex">
            <div className="wrap-right-pagel">
              <img src={ChartCircle} alt="" />
              <h2>Tap into the WAIO Edge</h2>
              <p className="flex align-start">
                <img src={dollar_icon} alt="" />
                Save your team valuable time and resources daily
              </p>
              <p className="flex align-start">
                <img src={calendar_icon} alt="" />
                Save on monthly fees by eliminating the need for other
                integrations
              </p>
              <p className="flex align-start">
                <img src={team_icon} alt="" />
                Empower your team with on-demand data analytics
              </p>
              <p className="flex align-start">
                <img src={location_icon} alt="" />
                Provide your customers with a fully streamlined shopping
                experience
              </p>
              <p className="flex align-start">
                <img src={box_icon} alt="" />
                Grow your business with ease and take full advantage of our
                enterprise software
              </p>
              <p className="flex align-start">
                <img src={checkmark_icon} alt="" />
                And so much more!
              </p>
            </div>
            <img src={BG3} alt="" />
          </div>
        </div>
      </section>

      <section className="section-detail pt-0">
        <div className="container-page">
          <img src={GetInTouch} alt="" className="w-full" />
          <div className="get-in-touch-s">
            <h3>We’re Here to Help – Get in Touch</h3>
            <p>Our support and sales team are at your service.</p>
            <WaiButtonOrange onClick={openModal}>Contact us</WaiButtonOrange>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
