import Modal, {
  useModalState,
  modalAnimation,
} from "react-simple-modal-provider";
import styled from "styled-components";
import bgRight from "../../imgs/bg-right.svg";
import { WaiButtonOrange, WaiButtonClose } from "../../styles/styled_button";
import useForm from "../Form/useForm";

const CloseButton = styled(WaiButtonClose)`
  margin: 0;
  position: absolute;
  right: 10px;
  width: 20px;
  height: 20px;
  z-index: 9;
  margin-top: 0 !important;
`;

const Wapper = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  width: 635px;
  padding-right: 375px;
  position: relative;
  @media screen and (max-width: 724px) {
    width: 90%;
    padding: 15px;
    margin: 0 auto;
  }
  &:after {
    @media screen and (max-width: 724px) {
      display: none;
    }
    content: "";
    background: url("${bgRight}");
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 358px;
    background-size: cover;
    border-radius: 5px;
  }
  h2 {
    @media screen and (max-width: 724px) {
      font-size: 30px;
      margin-top: 15px;
    }
    margin-top: 0;
    font-size: 42px;
    color: #000000;
    line-height: 50px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  .hint-text {
    @media screen and (max-width: 724px) {
      font-size: 14px;
      margin-bottom: 20px;
    }
    opacity: 0.6;
    font-family: Inter;
    font-size: 16px;
    color: #000000;
    line-height: 26px;
    font-weight: 300;
  }
  button {
    margin-top: 20px;
  }
`;

const WaiModal = ({ children }) => {
  const [isOpen, setOpen] = useModalState();

  const stateSchema = {
    first_name: { value: "", error: "" },
    last_name: { value: "", error: "" },
    email: { value: "", error: "" },
    question: { value: "", error: "" },
    company_brand: { value: "", error: "" },
    phone: { value: "", error: "" },
  };

  const stateValidatorSchema = {
    first_name: {
      required: true,
      validator: {
        func: (value) => /^[a-zA-Z]+$/.test(value),
        error: "Invalid first name format.",
      },
    },
    last_name: {
      required: true,
      validator: {
        func: (value) => /^[a-zA-Z]+$/.test(value),
        error: "Invalid last name format.",
      },
    },
    email: {
      required: true,
      validator: {
        func: (value) =>
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            value
          ),
        error: "Invalid email format.",
      },
    },
    question: {
      required: true,
    },
    company_brand: {
      required: true,
    },
    phone: {
      required: true,
      validator: {
        func: (value) =>
          /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(value),
        error: "Invalid phone format.",
      },
    },
  };

  function onSubmitForm(state, event) {
    
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(state, null, 2),
    };
    fetch(process.env.REACT_APP_API_URL + "contact/", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        resetFormValue();
        setOpen(false);
      });
  }

  const {
    values,
    errors,
    dirty,
    handleOnChange,
    handleOnSubmit,
    resetFormValue,
  } = useForm(stateSchema, stateValidatorSchema, onSubmitForm);

  const { first_name, last_name, email, question, company_brand, phone } =
    values;

  return (
    <Modal
      id={"BookDemo"}
      consumer={children}
      isOpen={isOpen}
      setOpen={setOpen}
      duration={250}
      animation={modalAnimation.scaleUp}
    >
      <Wapper>
        <CloseButton onClick={() => setOpen(false)} />
        <form className="my-form" onSubmit={handleOnSubmit}>
          <div className="row">
            <div className="col-12">
              <h2>Empower your Dispensary Today!</h2>
              <span className="hint-text">
                Fill out the form and a member from our team will reach out to
                schedule a time for us to connect and learn more about WAIO.
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label className="flex">
                  First name
                  {errors.first_name && dirty.first_name && (
                    <span className="error-message">{errors.first_name}</span>
                  )}
                </label>
                <input
                  type="text"
                  className="wai-input"
                  name="first_name"
                  value={first_name}
                  onChange={handleOnChange}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label className="flex">
                  Last name
                  {errors.last_name && dirty.last_name && (
                    <span className="error-message">{errors.last_name}</span>
                  )}
                </label>
                <input
                  type="text"
                  className="wai-input"
                  name="last_name"
                  value={last_name}
                  onChange={handleOnChange}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label className="flex">
                  E-mail
                  {errors.email && dirty.email && (
                    <span className="error-message">{errors.email}</span>
                  )}
                </label>
                <input
                  type="text"
                  className="wai-input"
                  name="email"
                  value={email}
                  onChange={handleOnChange}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label className="flex">
                  Phone Number
                  {errors.phone && dirty.phone && (
                    <span className="error-message">{errors.phone}</span>
                  )}
                </label>
                <input
                  type="text"
                  className="wai-input"
                  name="phone"
                  value={phone}
                  onChange={handleOnChange}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label className="flex">
                  Company/Brand
                  {errors.company_brand && dirty.company_brand && (
                    <span className="error-message">
                      {errors.company_brand}
                    </span>
                  )}
                </label>
                <input
                  type="text"
                  className="wai-input"
                  name="company_brand"
                  value={company_brand}
                  onChange={handleOnChange}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label className="flex">
                  Specific Quesitons or Notes
                  {errors.question && dirty.question && (
                    <span className="error-message">{errors.question}</span>
                  )}
                </label>
                <input
                  type="text"
                  className="wai-input"
                  name="question"
                  value={question}
                  onChange={handleOnChange}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <WaiButtonOrange className="w-full" type="submit">
                Book my Demo
              </WaiButtonOrange>
            </div>
          </div>
        </form>
      </Wapper>
    </Modal>
  );
};

export default WaiModal;
