import './App.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Home from './screens/home';
import Support from './screens/support';
import Header from './screens/layout/Header';
import Footer from './screens/layout/Footer';
import { ModalProvider } from "react-simple-modal-provider";
import modals from './components/Modals';

function App() {
  return (
    <Router>
     
        <div>
         <ModalProvider value={modals}>
         
          
          {/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */}
          <Switch>
              <Route path="/support">
                <>
                  <Support />
                </>
            </Route>
            <Route path="/">
                <>
                  <Header />
                  <Home />
                  <Footer />
                </>
            </Route>
          </Switch>
          
          </ModalProvider>
        </div>
      
    </Router>
  );
}

export default App;
