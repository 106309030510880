import styled from 'styled-components';
import close_icon from '../imgs/icons/close.svg'
export const WaiButton = styled.button`
    border-radius: 5px;
    background: transparent;
    border: 1.5px solid #FFFFFF;
    color: #fff;
    padding: 7.5px 16px;
    font-weight: 600;
    cursor: pointer;
`

export const WaiButtonOrange = styled.button`
    border-radius: 5px;
    border: 1.5px solid #F4BD64;
    color: #fff;
    padding: 10.5px;
    background: #F4BD64;
    font-size: 16px;
    font-weight: 600;
    color: #000000;
    text-align: center;
    cursor: pointer;
`

export const WaiButtonOrangeOutLine = styled.button`
    border-radius: 5px;
    border: 1.5px solid #F4BD64;
    color: #fff;
    padding: 10.5px;
    background: transparent;
    font-size: 16px;
    font-weight: 600;
    color: #000000;
    text-align: center;
    cursor: pointer;
`

export const WaiButtonOrangeOutLineBlack = styled.button`
    border-radius: 5px;
    border: 1.5px solid #222;
    color: #fff;
    padding: 7.5px 16px;
    background: transparent;
    font-size: 13px;
    color: #222;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
`

export const WaiButtonClose = styled.button`
    border: none;
    background: url('${close_icon}');
    background-repeat: no-repeat;
    cursor: pointer;
`