import React from "react";

import { Widget } from '@typeform/embed-react'

const Support = () => {
  return <Widget id="BC1zFIhw" style={{ width: '100%', height: '100vh' }} className="my-form" />
}


export default Support;
