import React from "react";
import { Link } from "react-router-dom";
import { WaiButtonOrangeOutLineBlack } from "../../styles/styled_button";
import styled from "styled-components";
import { useModal } from "react-simple-modal-provider";

import IG from "../../imgs/IG.svg";
import TWITTER from "../../imgs/twitter.svg";

const FooterWrapper = styled.nav`
  background: #fafafa;
  padding: 30px;
  ul {
    display: flex;
    align-items: center;
    li {
      padding: 10px 15px;
      opacity: 0.5;
      font-size: 12px;
      color: #000000;
    }
  }
`;

function Footer() {
  const { open: openModal } = useModal("BookDemo");
  return (
    <FooterWrapper>
      <div className="container-page">
        <div className="flex align-center justify-between">
          <ul className="right-nav">
            <li>
              <WaiButtonOrangeOutLineBlack onClick={openModal}>
                Book a demo
              </WaiButtonOrangeOutLineBlack>
            </li>
            <a className="footer-link" href="https://menu.waiosoft.com/terms">Terms & Conditions</a>
            <a className="footer-link" href="https://menu.waiosoft.com/privacy-policy">Privacy Policy</a>
          </ul>
          <ul>
            <li>© 2021 WAIO Soft Inc. All Right Reserved</li>
            {/* <li>
              <img src={IG} alt="" />
            </li>
            <li>
              <img src={TWITTER} alt="" />
            </li> */}
          </ul>
        </div>
      </div>
    </FooterWrapper>
  );
}

export default Footer;
