import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../imgs/logo.svg";
import { WaiButton } from "../../styles/styled_button";
import { useModal } from "react-simple-modal-provider";

function Header() {
  const { open: openModal } = useModal("BookDemo");
  return (
    <nav className="top-nav">
      <div className="container-page">
        <div className="flex align-center justify-between">
          <div className="brand-logo">
            <Logo />
          </div>
          <ul className="right-nav nav-mobile">
            <li>
              {/* <Link to="/">Platform</Link> */}
            </li>
            <li>
              <a href="https://app.waiosoft.com/">Login</a>
            </li>
            <li>
              <WaiButton onClick={openModal}>Book a demo</WaiButton>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Header;
